import { Link, LinkProps, useLocation } from 'react-router-dom';
import React, {
  ComponentType,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { v4 as uuidv4 } from 'uuid';
import {
  alpha,
  Box,
  CSSObject,
  Fade,
  Grow,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItemTextProps,
  Drawer as MuiDrawer,
  Stack,
  styled,
  SvgIconProps,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import logoWhite from '@/assets/omni-white.svg';
import symbolWhite from '@/assets/omni-white-symbol.svg';

export interface NavigationContextProps {
  open: boolean;
  toggleMenu(): void;
}

interface MenuItemWithChildren {
  icon: ComponentType<SvgIconProps>;
  text: ListItemTextProps['primary'];
  children?: MenuItem[];
}

interface MenuItemWithLink {
  icon: ComponentType<SvgIconProps>;
  text: ListItemTextProps['primary'];
  to?: LinkProps['to'];
}

export interface MenuItem extends MenuItemWithLink, MenuItemWithChildren {}

interface NavigationProps {
  menu: MenuItem[];
}

interface MenuItemWithIndentity extends MenuItem {
  id: string;
  children?: MenuItemWithIndentity[];
}

const STORAGE_STATE_NAME = 'navigation.state';

const drawerWidth = 320;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create(['width', 'background'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme, width = 15): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(width)})`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(width)})`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  backgroundColor: alpha('#000', 0.2),
  alignItems: 'center',
  justifyContent: 'center',
  height: 72,
  padding: theme.spacing(2),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DrawerFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  marginTop: 'auto',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth * 2,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const NavigationContext = createContext<NavigationContextProps>(
  {} as NavigationContextProps
);

export const NavigationProvider: React.FC<{
  children?: (props: NavigationContextProps) => any;
}> = ({ children }) => {
  const [open, setOpen] = useState(() => {
    const state = window.localStorage.getItem(STORAGE_STATE_NAME) ?? 'false';
    return state === 'true';
  });

  const toggleMenu = useCallback(() => {
    setOpen((old) => !old);
  }, []);

  const contextProps = useMemo(
    () => ({
      open,
      toggleMenu,
    }),
    [open, toggleMenu]
  );

  const saveState = useCallback((state: boolean) => {
    window.localStorage.setItem(STORAGE_STATE_NAME, state ? 'true' : 'false');
  }, []);

  useEffect(() => {
    saveState(open);
  }, [open, saveState]);

  return (
    <NavigationContext.Provider value={contextProps}>
      {typeof children === 'function' ? children(contextProps) : children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => {
  const context = useContext(NavigationContext);
  if (!context) throw new Error('Navigation Provider required');
  return context;
};

const Navigation: React.FC<NavigationProps> = ({ menu }) => {
  const mainTheme = useTheme();
  const navRef = useRef<any>();
  const { open, toggleMenu } = useNavigation();
  const [expandedItems, setExpandedItems] = useState<Record<string, boolean>>(
    {}
  );
  const location = useLocation();

  const menuItems = useMemo(() => {
    const withId = (items: NavigationProps['menu']): MenuItemWithIndentity[] =>
      items.map<any>((i) => ({
        id: uuidv4(),
        ...i,
        ...(i.children !== undefined ? { children: withId(i.children) } : {}),
      }));

    return withId(menu);
  }, [menu]);

  const handleToggle = useCallback((id: string) => {
    setExpandedItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  }, []);

  // Fechar todos os submenus ao fechar o drawer principal
  useEffect(() => {
    if (!open) {
      setExpandedItems({});
    }
  }, [open]);

  // Função para verificar se o item está ativo (página atual)
  const isActive = useCallback(
    (to?: LinkProps['to']) => {
      if (!to) return false;
      const path = typeof to === 'string' ? to : to.pathname || '';
      return path === location.pathname;
    },
    [location.pathname]
  );

  return (
    <Box ref={navRef} sx={{ flexGrow: 0, position: 'sticky', top: 0 }}>
      <Stack direction="row">
        <Drawer
          elevation={5}
          open={open}
          PaperProps={{
            elevation: 20,
            square: false,
            sx: (theme) => ({
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              bgcolor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            }),
          }}
          variant="permanent"
        >
          <DrawerHeader sx={{ justifyContent: 'center' }}>
            <Grow in={open} unmountOnExit>
              <img alt="Omni Brasil" height={50} src={logoWhite} />
            </Grow>
            <Grow in={!open} unmountOnExit>
              <img alt="Omni Brasil" height={20} src={symbolWhite} />
            </Grow>
          </DrawerHeader>
          <List sx={{ mt: 2 }}>
            {menuItems.map(({ icon: Icon, to, text, children, id }) => (
              <React.Fragment key={id}>
                <ListItem disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    component={(to ? Link : undefined) as any}
                    onClick={() => {
                      if (!open) toggleMenu();
                      if (children && children.length > 0) {
                        handleToggle(id);
                      }
                    }}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      alignItems: 'center',
                      px: 2.5,
                      backgroundColor: isActive(to)
                        ? alpha(mainTheme.palette.secondary.main, 0.2)
                        : 'inherit',
                    }}
                    to={to}
                  >
                    <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: (theme) => ({
                            bgcolor: theme.palette.secondary.main,
                            color: theme.palette.secondary.contrastText,
                          }),
                        },
                      }}
                      title={!open ? String(text) : ''}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: open ? 0 : '100%',
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        <Icon
                          sx={{
                            color: (theme: any) =>
                              isActive(to)
                                ? theme.palette.secondary.main
                                : theme.palette.primary.contrastText,
                          }}
                        />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                      primary={text}
                      primaryTypographyProps={{
                        variant: 'caption',
                        color: (theme) =>
                          isActive(to)
                            ? theme.palette.secondary.main
                            : theme.palette.primary.contrastText,
                        fontSize: 12,
                        textTransform: 'uppercase',
                      }}
                      sx={{
                        opacity: open ? 1 : 0,
                        width: !open ? 0 : 'auto',
                        flexGrow: 1,
                      }}
                    />
                    {!!children && children.length > 0 && (
                      <Fade in={open} unmountOnExit>
                        <KeyboardArrowDown
                          sx={{
                            color: (theme: any) =>
                              theme.palette.primary.contrastText,
                            transition: (theme) =>
                              theme.transitions.create('transform', {
                                easing: theme.transitions.easing.sharp,
                                duration:
                                  theme.transitions.duration.enteringScreen,
                              }),
                            transform: `rotate(${
                              expandedItems[id] ? 180 : 0
                            }deg)`,
                          }}
                        />
                      </Fade>
                    )}
                  </ListItemButton>
                </ListItem>
                {expandedItems[id] && children && children.length > 0 && (
                  <List sx={{ pl: 4 }}>
                    {children.map(
                      ({
                        icon: SubItemIcon,
                        text: subText,
                        id: subId,
                        to: subTo,
                      }) => (
                        <ListItemButton
                          component={(subTo ? Link : undefined) as any}
                          key={subId}
                          sx={{
                            pl: 4,
                            backgroundColor: isActive(subTo)
                              ? alpha(mainTheme.palette.secondary.main, 0.2)
                              : 'inherit',
                          }}
                          to={subTo}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: 3,
                              justifyContent: 'center',
                            }}
                          >
                            <SubItemIcon
                              sx={{
                                color: (theme: any) =>
                                  isActive(subTo)
                                    ? theme.palette.secondary.main
                                    : theme.palette.primary.contrastText,
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={subText}
                            primaryTypographyProps={{
                              color: (theme) =>
                                isActive(subTo)
                                  ? theme.palette.secondary.main
                                  : theme.palette.primary.contrastText,
                            }}
                          />
                        </ListItemButton>
                      )
                    )}
                  </List>
                )}
              </React.Fragment>
            ))}
          </List>
          <DrawerFooter>
            <Typography variant="body2">
              v{process.env.REACT_APP_VERSION}
            </Typography>
          </DrawerFooter>
        </Drawer>
      </Stack>
    </Box>
  );
};

export default Navigation;
