import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { isEmpty } from 'lodash';
import {
  Box,
  Chip,
  CircularProgress,
  Collapse,
  IconButton,
  Pagination,
  Paper,
  Popover,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete,
  Edit,
  Error,
  FilterList,
  Home,
} from '@mui/icons-material';
import Http from '@/services/http';
import { IWorkshop } from '@/models/workshop-model';
import { IPagination } from '@/models/pagination-model';
import { useDrawer } from '@/contexts/drawer';
import { useDialog } from '@/contexts/dialog';
import Header from '@/components/layouts/header';
import InfoLabel from '@/components/Info/label';
import FilterTags from '@/components/Info/filter-tags';

import WorkshopFilterForm from './components/workshop-filter-form';
import UpdateWorkshopForm from '../update-workshop';
import CreateWorkshopForm from '../create-workshop';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  transition: theme.transitions.create('background', {
    duration: theme.transitions.duration.short,
  }),
  '&:nth-of-type(odd), :hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ITEMS_PER_PAGE = 50;

const WorkshopList: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [workshops, setworkshops] = useState<IPagination<IWorkshop>>({
    docs: [],
    limit: 0,
    offset: 0,
    totalDocs: 0,
    totalPages: 0,
    hasNextPage: false,
    hasPrevPage: false,
    pagingCounter: 0,
  });

  const [loadingWorkshops, setLoadingWorkshops] = useState<boolean>(false);

  const submitFilter = useCallback(
    async (values: Record<string, any>) => {
      Object.entries(values).forEach(([key, value]) => {
        let queryValue;
        if (value instanceof Date) {
          queryValue = value.toISOString();
        } else if (!isEmpty(value)) {
          queryValue = value;
          if (typeof value === 'object' && value._id) {
            queryValue = value._id;
          }
        }

        if (typeof value === 'boolean') {
          if (value) queryValue = 'true';
          else queryValue = undefined;
        }

        if (queryValue) {
          searchParams.set(key, queryValue);
        } else {
          searchParams.delete(key);
        }
      });

      if (!values.page) {
        searchParams.delete('page');
      }

      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const fetchWorkshops = useCallback(async () => {
    setLoadingWorkshops(true);

    const { status, data } = await Http.get('mechanical', {
      params: {
        ...Object.fromEntries(searchParams),
        limit: ITEMS_PER_PAGE,
      },
    });

    if (status === 200) setworkshops(data);

    setLoadingWorkshops(false);
  }, [searchParams]);

  const drawer = useDrawer();
  const dialog = useDialog();

  const { enqueueSnackbar } = useSnackbar();

  const createWorkshop = () => {
    drawer.open({
      options: {
        width: 400,
      },
      element: <CreateWorkshopForm />,
      onClose(createdWorkshop) {
        if (createdWorkshop) {
          fetchWorkshops();
        }
      },
    });
  };

  const editWorkshop = (workshop: IWorkshop) => {
    drawer.open({
      element: <UpdateWorkshopForm workshop={workshop} />,
      onClose(updatedworkshop) {
        if (updatedworkshop) fetchWorkshops();
      },
    });
  };

  const deleteWorkshop = (workshop: IWorkshop) => {
    dialog.open({
      title: 'Confirmação',
      message: `Deseja excluir a oficina ${workshop.name}?`,
      buttons: [
        {
          label: 'Não',
        },
        {
          label: 'Sim',
          options: {
            variant: 'outlined',
            color: 'error',
          },
          async callback() {
            const { status } = await Http.delete(`mechanical/${workshop._id}`);

            if (status === 200) {
              enqueueSnackbar('Oficina excluída com sucesso!', {
                variant: 'success',
              });
              await fetchWorkshops();
              return;
            }
            if (status === 409) {
              enqueueSnackbar(
                'Existem RIFs associados á esta oficina, por favor remova as dependências antes de tentar novamente.',
                {
                  variant: 'error',
                }
              );
              return;
            }

            enqueueSnackbar('Ocorreu um erro ao excluir a oficina!', {
              variant: 'error',
            });
          },
        },
      ],
    });
  };

  useEffect(() => {
    fetchWorkshops();
  }, [fetchWorkshops]);

  const popupState = usePopupState({ variant: 'popover', popupId: 'filter' });

  return (
    <Stack spacing={1}>
      <Header
        breadcrumbs={[
          {
            icon: Home,
          },
          {
            text: 'Configurações',
          },
          {
            text: 'Oficinas',
          },
        ]}
        headerChildren={
          <Stack alignItems="center" direction="row" spacing={1}>
            <FilterTags
              tagDefs={[
                {
                  paramKey: 'name',
                  valueGetter(value) {
                    return `Nome ${value}`;
                  },
                },
                {
                  paramKey: 'active',
                  valueGetter() {
                    return 'Ativa';
                  },
                },
              ]}
            />
            <IconButton color="secondary" {...bindTrigger(popupState)}>
              <FilterList />
            </IconButton>
            <Popover {...bindPopover(popupState)}>
              <WorkshopFilterForm
                onSubmit={async (values) => {
                  popupState.close();
                  submitFilter(values);
                }}
              />
            </Popover>
            <IconButton
              color="secondary"
              onClick={() => {
                createWorkshop();
              }}
            >
              <AddIcon />
            </IconButton>
          </Stack>
        }
        title="OFICINAS"
      />
      <Paper sx={{ width: '100%' }}>
        <Collapse in={loadingWorkshops} unmountOnExit>
          <Box
            alignItems="center"
            bgcolor="grey.100"
            display="flex"
            justifyContent="center"
            p={4}
          >
            <CircularProgress />
          </Box>
        </Collapse>
        <Collapse in={!loadingWorkshops && workshops.docs.length === 0}>
          <Stack
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ p: 2 }}
          >
            <Typography variant="caption">
              <Error sx={{ fontSize: '56px' }} />
            </Typography>
            <Typography color="GrayText" variant="h5">
              Nenhuma oficina encontrada
            </Typography>
          </Stack>
        </Collapse>
        <Collapse in={!loadingWorkshops || workshops.docs.length > 0}>
          <TableContainer sx={{ overflowY: 'auto' }}>
            <Table
              aria-label="workshops list"
              sx={{ maxWidth: '100%', whiteSpace: 'nowrap' }}
            >
              <TableBody>
                {workshops.docs?.map((workshop) => (
                  <StyledTableRow key={workshop._id}>
                    <TableCell sx={{ width: '1%' }}>
                      <Tooltip title="Editar">
                        <IconButton onClick={() => editWorkshop(workshop)}>
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={{ width: '1%' }}>
                      <Stack alignItems="center">
                        {workshop.active ? (
                          <Chip color="success" label="Ativa" />
                        ) : (
                          <Chip color="default" label="Não ativa" />
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <InfoLabel
                        items={[
                          {
                            title: 'Nome',
                            value: workshop.name || '-',
                          },
                        ]}
                      />
                    </TableCell>
                    <TableCell sx={{ width: '1%' }}>
                      <IconButton onClick={() => deleteWorkshop(workshop)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
        {workshops.totalPages > 1 && (
          <Box sx={{ m: 2 }}>
            <Pagination
              count={workshops.totalPages}
              onChange={(_, selectedPage) =>
                submitFilter({ page: selectedPage.toString() })
              }
              page={workshops.offset}
            />
          </Box>
        )}
      </Paper>
    </Stack>
  );
};

export default WorkshopList;
