import { FormContainer } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';

import { useSnackbar } from 'notistack';
import { Box, Button, Stack, Typography } from '@mui/material';
import Http from '@/services/http';
import { IAircraftModels } from '@/models/aircraft-models';
import { useItemDrawer } from '@/contexts/drawer';

import AircraftModelsForm from '../components/aircraft-models-form';

interface IUpdateAircraftModels {
  aircraftModels: IAircraftModels;
}

const UpdateAircraftModelsForm: React.FC<IUpdateAircraftModels> = ({
  aircraftModels,
}) => {
  const form = useForm({
    defaultValues: aircraftModels,
  });

  const { enqueueSnackbar } = useSnackbar();

  const drawerItem = useItemDrawer();

  const updateAircraftModelsSubmit = useCallback(
    async (values: any) => {
      const { status, data } = await Http.put(
        `aircraft-models/${aircraftModels._id}`,
        values
      );

      if (status === 200) {
        enqueueSnackbar('Modelo da aeronave atualizada com sucesso!', {
          variant: 'success',
        });
        drawerItem.close(data);
      } else
        enqueueSnackbar('Ocorreu um erro ao atualizar o modelo da aeronave!', {
          variant: 'error',
        });
    },
    [drawerItem, aircraftModels._id, enqueueSnackbar]
  );

  return (
    <Box sx={{ p: 4, flexGrow: 1 }}>
      <Stack spacing={2} sx={{ height: '100%' }}>
        <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
          ATUALIZAR MODELO AERONAVE
        </Typography>
        <FormContainer
          formContext={form}
          FormProps={{
            style: { flexGrow: 1, display: 'flex', flexDirection: 'column' },
          }}
          onSuccess={updateAircraftModelsSubmit}
        >
          <Box sx={{ p: 2, flexGrow: 1 }}>
            <AircraftModelsForm />
          </Box>
          <Stack direction="row" spacing={1}>
            <Button type="submit" variant="contained">
              Atualizar
            </Button>
            <Button onClick={() => drawerItem.close()}>Cancelar</Button>
          </Stack>
        </FormContainer>
      </Stack>
    </Box>
  );
};

export default UpdateAircraftModelsForm;
