import { FormContainer } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';

import { useSnackbar } from 'notistack';
import { Box, Button, Stack, Typography } from '@mui/material';
import Http from '@/services/http';
import { useItemDrawer } from '@/contexts/drawer';

import AircraftForm from '../components/aircraft-form';

export interface ICreateAircraft {
  active: boolean;
  prefix: string;
  sn: string;
  backup: boolean;
  observation: string;
  modelAlias: any;
  manufacturerName?: string;
}

const CreateAircraftForm: React.FC = () => {
  const form = useForm();
  const drawerItem = useItemDrawer();

  const { enqueueSnackbar } = useSnackbar();

  const createAircraftSubmit = useCallback(
    async (values: any) => {
      const payload: ICreateAircraft = {
        active: values.active ?? false,
        backup: values.backup ?? false,
        observation: values.observation,
        sn: values.sn,
        modelAlias: values._model._id,
        prefix: values.prefix,
      };
      const { status, data } = await Http.post('aircrafts', payload);

      if (status === 201) {
        enqueueSnackbar('Aeronave criada com sucesso!', { variant: 'success' });
        drawerItem.close(data);
      } else
        enqueueSnackbar('Ocorreu um erro ao criar a aeronave!', {
          variant: 'error',
        });
    },
    [drawerItem, enqueueSnackbar]
  );

  return (
    <Box sx={{ p: 4, flexGrow: 1 }}>
      <Stack spacing={2} sx={{ height: '100%' }}>
        <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
          CRIAR AERONAVE
        </Typography>
        <FormContainer
          formContext={form}
          FormProps={{
            style: { flexGrow: 1, display: 'flex', flexDirection: 'column' },
          }}
          onSuccess={createAircraftSubmit}
        >
          <Box sx={{ p: 2, flexGrow: 1 }}>
            <AircraftForm />
          </Box>
          <Stack direction="row" spacing={1}>
            <Button type="submit" variant="contained">
              Criar
            </Button>
            <Button onClick={() => drawerItem.close()}>Cancelar</Button>
          </Stack>
        </FormContainer>
      </Stack>
    </Box>
  );
};

export default CreateAircraftForm;
