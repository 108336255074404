import { Route, Routes } from 'react-router-dom';

import WorkshopList from './elements/workshop/list-workshop';
import UserList from './elements/user/user-list';
import ProfileList from './elements/profile/list-profile';
import ObservedEventList from './elements/observed-event/list-observed-event';
import CancelReasonList from './elements/cancel-reason/list-cancel-reason';
import BaseList from './elements/base/list-base';
import AircraftList from './elements/aircraft/list-aircraft';
import AircraftModelsList from './elements/aircraft-models/list-aircraft-models';

const Setting = () => (
  <Routes>
    <Route path="users">
      <Route element={<UserList />} index />
      <Route element={<ProfileList />} path="groups" />
    </Route>
    <Route element={<ObservedEventList />} path="observed-events" />
    <Route element={<CancelReasonList />} path="cancel-reasons" />
    <Route element={<BaseList />} path="bases" />
    <Route element={<WorkshopList />} path="workshops" />
    <Route element={<AircraftList />} path="airplanes" />
    <Route element={<AircraftModelsList />} path="aircraft-models" />
  </Routes>
);

export default Setting;
