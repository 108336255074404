import { useSearchParams } from 'react-router-dom';
import { TextFieldElement } from 'react-hook-form-mui';
import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useMemo } from 'react';

import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { getCleanValues } from '@/helpers/get-clean-values';

interface AircraftModelsFilterFormProps {
  onSubmit(values: Record<string, any>): Promise<void>;
}

const AircraftModelsFilterForm: React.FC<AircraftModelsFilterFormProps> = ({
  onSubmit,
}) => {
  const [searchParams] = useSearchParams();

  const form = useForm({
    defaultValues: Object.fromEntries(searchParams),
  });

  const cleanForm = useMemo(() => getCleanValues(form.getValues()), [form]);

  const clearForm = useCallback(() => {
    form.reset(cleanForm);
    setTimeout(() => {
      form.handleSubmit(onSubmit)();
    }, 0);
  }, [form, cleanForm, onSubmit]);

  return (
    <Box sx={{ p: 2, minWidth: '50vw' }}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Typography variant="caption">Filtros</Typography>
          <Stack spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextFieldElement
                  disabled={false}
                  label="Pesquisa"
                  name="search"
                />
              </Grid>
            </Grid>
            <Stack direction="row" spacing={1} sx={{ alignSelf: 'flex-end' }}>
              <Button onClick={clearForm}>Limpar</Button>
              <Button color="secondary" type="submit" variant="contained">
                Aplicar
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </Box>
  );
};

export default AircraftModelsFilterForm;
