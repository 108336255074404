import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import { isEmpty } from 'lodash';
import {
  Box,
  Chip,
  CircularProgress,
  Collapse,
  IconButton,
  Pagination,
  Paper,
  Popover,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete,
  Edit,
  Error,
  FilterList,
  Home,
} from '@mui/icons-material';
import Http from '@/services/http';
import { IPagination } from '@/models/pagination-model';
import { IAircraftModels } from '@/models/aircraft-models';
import { useDrawer } from '@/contexts/drawer';
import { useDialog } from '@/contexts/dialog';
import Header from '@/components/layouts/header';
import InfoLabel from '@/components/Info/label';
import FilterTags from '@/components/Info/filter-tags';

import AircraftModelsFilterForm from './components/aircraft-models-filter-form';
import UpdateAircraftModelsForm from '../update-aircraft-models';
import CreateAicraftModelsForm from '../create-aircraft-models';

const ITEMS_PER_PAGE = 50;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  transition: theme.transitions.create('background', {
    duration: theme.transitions.duration.short,
  }),
  '&:nth-of-type(odd), :hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const AircraftModelsList: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [aircrafts, setAircraftsModels] = useState<
    IPagination<IAircraftModels>
  >({
    docs: [],
    limit: 0,
    offset: 0,
    totalDocs: 0,
    totalPages: 0,
    hasNextPage: false,
    hasPrevPage: false,
    pagingCounter: 0,
  });

  const [loadingAircraftsModels, setLoadingAircraftsModels] =
    useState<boolean>(false);

  const submitFilter = useCallback(
    async (values: Record<string, any>) => {
      Object.entries(values).forEach(([key, value]) => {
        let queryValue;
        if (value instanceof Date) {
          queryValue = value.toISOString();
        } else if (!isEmpty(value)) {
          queryValue = value;
          if (typeof value === 'object' && value._id) {
            queryValue = value._id;
          }
        }

        /* Check is value is a boolean */
        if (typeof value === 'boolean') {
          if (value) queryValue = 'true';
          else queryValue = undefined;
        }

        if (queryValue) {
          searchParams.set(key, queryValue);
        } else {
          searchParams.delete(key);
        }
      });

      if (!values.page) {
        searchParams.delete('page');
      }

      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const fetchAircrafts = useCallback(async () => {
    setLoadingAircraftsModels(true);

    const { status, data } = await Http.get('aircraft-models', {
      params: {
        ...Object.fromEntries(searchParams),
        limit: ITEMS_PER_PAGE,
      },
    });

    if (status === 200) setAircraftsModels(data);

    setLoadingAircraftsModels(false);
  }, [searchParams]);

  const drawer = useDrawer();

  const createAircraftModels = () => {
    drawer.open({
      options: {
        width: 400,
      },
      element: <CreateAicraftModelsForm />,
      onClose(createdAircraftModels) {
        if (createdAircraftModels) {
          fetchAircrafts();
        }
      },
    });
  };

  const editAircraftModels = (aicraftModels: IAircraftModels) => {
    drawer.open({
      options: {
        width: 400,
      },
      element: <UpdateAircraftModelsForm aircraftModels={aicraftModels} />,
      onClose(updatedAircraftModels) {
        if (updatedAircraftModels) fetchAircrafts();
      },
    });
  };

  useEffect(() => {
    fetchAircrafts();
  }, [fetchAircrafts]);

  const popupState = usePopupState({ variant: 'popover', popupId: 'filter' });

  return (
    <Stack spacing={1}>
      <Header
        breadcrumbs={[
          {
            icon: Home,
          },
          {
            text: 'Configurações',
          },
          {
            text: 'Modelo de Aeronaves',
          },
        ]}
        headerChildren={
          <Stack alignItems="center" direction="row" spacing={1}>
            <FilterTags
              tagDefs={[
                {
                  paramKey: 'active',
                  valueGetter() {
                    return `Ativa`;
                  },
                },
                {
                  paramKey: 'model',
                  valueGetter(value) {
                    return `Modelo ${value}`;
                  },
                },
                {
                  paramKey: 'manufacturer',
                  valueGetter(value) {
                    return `Fabricante ${value}`;
                  },
                },
              ]}
            />
            <IconButton color="secondary" {...bindTrigger(popupState)}>
              <FilterList />
            </IconButton>
            <Popover sx={{ marginRight: 2 }} {...bindPopover(popupState)}>
              <AircraftModelsFilterForm
                onSubmit={async (values) => {
                  popupState.close();
                  submitFilter(values);
                }}
              />
            </Popover>
            <IconButton
              color="secondary"
              onClick={() => {
                createAircraftModels();
              }}
            >
              <AddIcon />
            </IconButton>
          </Stack>
        }
        title="Modelo de Aeronaves"
      />
      <Paper sx={{ width: '100%' }}>
        <Collapse in={loadingAircraftsModels} unmountOnExit>
          <Box
            alignItems="center"
            bgcolor="grey.100"
            display="flex"
            justifyContent="center"
            p={4}
          >
            <CircularProgress />
          </Box>
        </Collapse>
        <Collapse in={!loadingAircraftsModels && aircrafts.docs.length === 0}>
          <Stack
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ p: 2 }}
          >
            <Typography variant="caption">
              <Error sx={{ fontSize: '56px' }} />
            </Typography>
            <Typography color="GrayText" variant="h5">
              Nenhuma modelos de aeronave encontrada
            </Typography>
          </Stack>
        </Collapse>
        <Collapse
          in={!loadingAircraftsModels && aircrafts.docs.length > 0}
          unmountOnExit
        >
          <TableContainer sx={{ overflowY: 'auto' }}>
            <Table
              aria-label="aircraft models list"
              sx={{ maxWidth: '100%', whiteSpace: 'nowrap' }}
            >
              <TableBody>
                {aircrafts.docs
                  ?.sort((a, b) => a.manufacturer.localeCompare(b.manufacturer))
                  .map((aircraft) => (
                    <StyledTableRow key={aircraft._id}>
                      <TableCell sx={{ width: '1%' }}>
                        <Tooltip title="Editar">
                          <IconButton
                            onClick={() => editAircraftModels(aircraft)}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell sx={{ paddingLeft: 2 }}>
                        <Stack alignItems="flex-start">
                          {aircraft.active ? (
                            <Chip color="success" label="Ativa" />
                          ) : (
                            <Chip label="Não ativa" />
                          )}
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <InfoLabel
                          items={[
                            {
                              title: 'Modelo',
                              value: `${aircraft.model}`,
                            },
                          ]}
                        />
                      </TableCell>
                      <TableCell>
                        <InfoLabel
                          items={[
                            {
                              title: 'Fabricante',
                              value: `${aircraft.manufacturer}`,
                            },
                          ]}
                        />
                      </TableCell>
                      <TableCell sx={{ width: '1%' }} />
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
        {aircrafts.totalPages > 1 && (
          <Box sx={{ m: 2 }}>
            <Pagination
              count={aircrafts.totalPages}
              onChange={(_, selectedPage) =>
                submitFilter({ page: selectedPage.toString() })
              }
              page={aircrafts.offset}
            />
          </Box>
        )}
      </Paper>
    </Stack>
  );
};

export default AircraftModelsList;
