import { SwitchElement, TextFieldElement } from 'react-hook-form-mui';

import { Grid } from '@mui/material';

const AircraftModelsForm: React.FC = () => (
  <Grid container spacing={2}>
    <Grid item sm={12}>
      <TextFieldElement label="Modelo" name="model" required />
    </Grid>
    <Grid item sm={12}>
      <TextFieldElement label="Fabricante" name="manufacturer" required />
    </Grid>
    <Grid item xs={12}>
      <SwitchElement label="Ativa" name="active" />
    </Grid>
  </Grid>
);

export default AircraftModelsForm;
