import {
  SelectElement,
  SwitchElement,
  TextFieldElement,
} from 'react-hook-form-mui';
import { useCallback, useEffect, useState } from 'react';

import { Box, CircularProgress, Collapse, Grid } from '@mui/material';
import Http from '@/services/http';
import { IAircraftModel } from '@/models/aircraft-model-model';

const AircraftForm: React.FC = () => {
  const [models, setModels] = useState<IAircraftModel[]>([]);
  const [loadingModels, setLoadingModels] = useState(true);

  const fetchModels = useCallback(async () => {
    const { data, status } = await Http.get('aircraft-models');

    if (status === 200) {
      setModels(data?.docs);
      setLoadingModels(false);
    }
  }, []);

  useEffect(() => {
    fetchModels();
  }, [fetchModels]);

  return (
    <>
      <Collapse in={loadingModels} unmountOnExit>
        <Box alignItems="center" display="flex" justifyContent="center" p={4}>
          <CircularProgress />
        </Box>
      </Collapse>
      <Collapse in={!loadingModels} unmountOnExit>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SwitchElement
              disabled={loadingModels}
              label="Ativa"
              name="active"
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldElement
              disabled={loadingModels}
              label="Marca"
              name="prefix"
            />
          </Grid>
          <Grid item xs={12}>
            <SelectElement
              disabled={loadingModels}
              label="Modelo"
              name="_model"
              options={models.map((model) => ({
                id: model._id,
                label: `${model.manufacturer} - ${model.model}`,
              }))}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldElement label="Número de Série (S/N)" name="sn" />
          </Grid>
          <Grid item xs={6}>
            <SwitchElement
              disabled={loadingModels}
              label="Backup"
              name="backup"
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldElement label="Observações" name="observation" />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};
export default AircraftForm;
