import { FormContainer } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';

import { useSnackbar } from 'notistack';
import { Box, Button, Stack, Typography } from '@mui/material';
import Http from '@/services/http';
import { IAircraft } from '@/models/aircraft-model';
import { useItemDrawer } from '@/contexts/drawer';

import AircraftForm from '../components/aircraft-form';

interface IUpdateAircraft {
  aircraft: IAircraft;
}

const UpdateAircraftForm: React.FC<IUpdateAircraft> = ({ aircraft }) => {
  const form = useForm({
    defaultValues: {
      ...aircraft,
      _model: aircraft._model._id,
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  const drawerItem = useItemDrawer();

  const updateAircraftSubmit = useCallback(
    async (values: any) => {
      const { status, data } = await Http.put(
        `aircrafts/${aircraft._id}`,
        values
      );

      if (status === 200) {
        enqueueSnackbar('Aeronave criada com sucesso!', { variant: 'success' });
        drawerItem.close(data);
      } else
        enqueueSnackbar('Ocorreu um erro ao criar a aeronave!', {
          variant: 'error',
        });
    },
    [drawerItem, aircraft._id, enqueueSnackbar]
  );

  return (
    <Box sx={{ p: 4, flexGrow: 1 }}>
      <Stack spacing={2} sx={{ height: '100%' }}>
        <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
          ATUALIZAR AERONAVE
        </Typography>
        <FormContainer
          formContext={form}
          FormProps={{
            style: {
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
            },
          }}
          onSuccess={updateAircraftSubmit}
        >
          <Box sx={{ p: 2, flexGrow: 1 }}>
            <AircraftForm />
          </Box>
          <Stack direction="row" spacing={1}>
            <Button type="submit" variant="contained">
              Atualizar
            </Button>
            <Button onClick={() => drawerItem.close()}>Cancelar</Button>
          </Stack>
        </FormContainer>
      </Stack>
    </Box>
  );
};

export default UpdateAircraftForm;
